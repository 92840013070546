@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1440px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
}

.card {
  width: 500px;
  margin: auto;
  position: relative;
  background-color: $white;
  box-shadow: $joyBoxShadow;
  padding: 70px;
  border-radius: 3px;
}

.legend {
  margin-top: 50px;
  margin-bottom: 30px;
}

.cardActionsExtension {
  margin-right: 30px;
}

.cardActions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.underCardExtension {
  width: 500px;
  margin: auto;
}
