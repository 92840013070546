@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  width: 100vw;
  max-width: 1440px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
}

.card {
  width: 500px;
  margin: auto;
  position: relative;
  background-color: $white;
  box-shadow: $joyBoxShadow;
  padding: 70px;
  border-radius: 3px;
}

.header {
  margin-top: 50px;
  margin-bottom: 30px;
}

.paragraph {
  text-align: left;
}

.paragraphTeam {
  text-align: right;
}
