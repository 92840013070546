.clientName {
  margin-top: 80px;
  margin-bottom: 50px;
  margin-left: 100px;
}

.clientNameMobile {
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: 15px;
}
