@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  flex: 1 1 0%;
  padding-left: 50px;
  padding-right: 50px;
  margin: auto;
}

.wrapperMobile {
  padding-left: 20px;
  padding-right: 20px;
}

.fixedHeader {
  margin-top: 100px;
}

.contentFiller {
  height: 100px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
