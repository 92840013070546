@import '@assets/css/variables.scss';

.modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1200;
}

.clickPassthrough {
  pointer-events: none;
}

.basicModal {
  align-items: center;
  justify-content: center;
  background-color: $joyOverlayBackground;
}
