@import '@assets/css/variables.scss';

.wrapper {
  position: relative;
  width: 100vw;
  max-width: 1440px;
  min-width: 500px;
  height: 85px;
  margin: 0 auto;
  top: calc(100% - 115px);
  border-radius: 3px;
  overflow: hidden;
  opacity: 0.95;
  box-shadow: $joyBoxShadowMedia;
  pointer-events: all;
}

.content {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.label {
  flex: 1;
}

.actions {
  flex: 0;
}

.progressBar {
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 4px;
  background-color: $joyPrimary;
}

.defaultColor {
  background-color: $joySnackbarBackgroundDefault;
  color: $joySnackbarTextDefault;
}

.infoColor {
  background-color: $joySnackbarBackgroundInfo;
  color: $joySnackbarTextInfo;
}

.warningColor {
  background-color: $joySnackbarBackgroundWarning;
  color: $joySnackbarTextWarning;
}

.successColor {
  background-color: $joySnackbarBackgroundSuccess;
  color: $joySnackbarTextSuccess;
}

.errorColor {
  background-color: $joySnackbarBackgroundError;
  color: $joySnackbarTextError;
}
