@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.userName {
  font-size: 16px;
  font-weight: 400;
}

.initials {
  font-size: 16px;
  font-weight: 600;
  color: $white;
  background-color: $joyPrimary;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
}
