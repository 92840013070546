@import '@assets/css/variables.scss';

.wrapper {
  position: fixed;
  width: 500px;
  min-height: 200px;
  left: 50px;
  bottom: 30px;
  border-radius: 3px;
  opacity: 0.95;
  box-shadow: $joyBoxShadowMedia;
  padding: 40px;
  background-color: $joySnackbarBackgroundDefault;
  color: $joySnackbarTextDefault;
  pointer-events: all;
}

.content {
  display: flex;
  flex-direction: column;
  max-height: 700px;
  align-items: center;
  overflow: auto;
}

.loadingLoop {
  padding: 20px 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  flex: 1;
}

.actions {
  flex: 0;
}

.uploadItem {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $joyUploadBorderColor;

  &:last-child {
    border-bottom-color: #00000000;
  }

  &:first-child {
    margin-top: 0;
  }
}

.itemThumbnail {
  width: 75px;
  height: 75px;
  margin-right: 20px;
  background-color: gray;
}

.videoIconOverlay {
  position: relative;
  top: -23px;
  left: 2px;
}

.itemInfo {
  flex: 1;
}

.itemActions {
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.progressBar {
  height: 2px;
  width: 100%;
  margin: 10px 0;
  background-color: $joyProgressBar;
}

.currentProgress {
  height: 2px;
  background-color: $joyProgressBarCompleted;
}
