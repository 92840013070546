@import '@assets/css/variables.scss';

.button {
  border: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;

  &:disabled {
    cursor: wait;
  }
}
