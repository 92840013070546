@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  align-items: flex-end;
}

.extension {
  margin-left: 10px;
  // font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 10px;
  letter-spacing: 0.9px;
  color: $joyLogoColor;
}
