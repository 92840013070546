@import '@assets/css/variables.scss';

.textCommon {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.clickable {
  cursor: pointer;
}

.bodyVariant {
  font-size: 14px;
  font-weight: 400;
}

.bodySmallVariant {
  font-size: 12px;
  font-weight: 400;
}

.captionVariant {
  font-size: 10px;
  font-weight: 400;
}

.titleVariant {
  font-size: 24px;
  font-weight: 400;
}

.titleLargeVariant {
  font-size: 48px;
  font-weight: 400;
}

.titleSmallVariant {
  font-size: 18px;
  font-weight: 400;
}

.subtitleVariant {
  font-size: 16px;
  font-weight: 400;
}

.paragraphVariant {
  font-size: 14px;
  font-weight: 500;
}

.headingVariant {
  font-size: 36px;
  font-weight: 400;
}

.defaultColor {
  color: $joyTextDefault !important;
}

.disabledColor {
  color: $joyTextDisabled !important;
}

.mutedColor {
  color: $joyTextMuted !important;
}

.primaryColor {
  color: $joyTextPrimary !important;
}

.secondaryColor {
  color: $joyTextSecondary !important;
}

.errorColor {
  color: $joyTextError !important;
}

.whiteColor {
  color: $joyTextWhite !important;
}
