@import '@assets/css/variables.scss';

.link {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $joySecondary;

  &:link {
    color: $joySecondary;
  }

  &:visited {
    color: $joySecondary;
  }

  &:hover {
    color: $joySecondaryLight;
  }

  &:active {
    color: $joyPrimary;
  }
}
