// Colors
$white: #ffffff;
$black: #000000;

// Theme defaults
$joyPrimary: #ffbe00;
$joySecondaryLight: #4dd5f3;
$joySecondary: #5dc9e2;
$joySecondaryDark: #3ab3ce;
$joyMutedLight: #dcdcdc; /* gainsboro */
$joyMuted: #949494;
$joyMutedDark: #8a8a8a;
$joyError: #eb4d49;
$joySuccess: #00a691;
$joyOffWhite: #f9f9f9;
$joyDarkWhite: #e0e0e0;
$joyBlack: #333333;
$joyDisabled: #a6a6a7;
$joyLogoColor: #bcbcbc;
$joyShadowColor: #00000008;
$joyShadowColorMedia: #00000066;
$joyOverlayBackground: #00000080;

// Theme components
$joyTextDefault: $joyBlack;
$joyTextMuted: $joyMuted;
$joyTextDisabled: $joyDisabled;
$joyTextPrimary: $joyPrimary;
$joyTextSecondary: $joySecondary;
$joyTextError: $joyError;
$joyTextWhite: $white;

$joyButtonBackgroundDefault: $joyDarkWhite;
$joyButtonTextDefault: $joyBlack;
$joyButtonBackgroundDisabled: $joyDarkWhite;
$joyButtonTextDisabled: $joyDisabled;
$joyButtonBackgroundMuted: $joyDarkWhite;
$joyButtonTextMuted: $joyMuted;
$joyButtonBackgroundPrimary: $joyPrimary;
$joyButtonTextPrimary: $white;
$joyButtonBackgroundSecondary: $joySecondary;
$joyButtonTextSecondary: $white;
$joyButtonBackgroundError: $joyError;
$joyButtonTextError: $white;

$joySnackbarBackgroundDefault: $joyBlack;
$joySnackbarTextDefault: $joyDarkWhite;
$joySnackbarBackgroundInfo: #0089ff;
$joySnackbarTextInfo: $white;
$joySnackbarBackgroundWarning: #ffc800;
$joySnackbarTextWarning: $white;
$joySnackbarBackgroundSuccess: $joySuccess;
$joySnackbarTextSuccess: $white;
$joySnackbarBackgroundError: $joyError;
$joySnackbarTextError: $white;

$joyProgressBar: #7e7c7c;
$joyProgressBarCompleted: #ffbe00;

$joyIconDefault: $joyBlack;
$joyIconDisabled: $joyDisabled;
$joyIconMuted: $joyMutedLight;
$joyIconPrimary: $joyPrimary;
$joyIconSecondary: $joySecondary;
$joyIconError: $joyError;

$joyHeaderBorderColor: #efefef;
$joyFooterBorderColor: $joyMutedLight;
$joyBorderColor: $joyMutedLight;
$joyUploadBorderColor: #ffffff33;

// Joy Custom CSS Values
$joyBoxShadow: $joyShadowColor 0 10px 8px 0;
$joyBoxShadowHeader: $joyShadowColor 0 2px 4px 0;
$joyBoxShadowMedia: $joyShadowColorMedia 0 4px 10px 0;

// Spacing

$spacingUnit: 3px;
$spaceNone: 0;
$spaceXs: $spacingUnit * 0.25;
$spaceSm: $spacingUnit * 0.5;
$spaceMd: $spacingUnit;
$spaceLg: $spacingUnit * 1.5;
$spaceXl: $spacingUnit * 3;
