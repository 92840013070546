@import '@assets/css/variables.scss';

.wrapper {
  width: 50px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  animation: bounceDelay 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
}

.defaultColor {
  & > div {
    background-color: $joyBlack;
  }
}

.disabledColor {
  & > div {
    background-color: $joyDisabled;
  }
}

.mutedColor {
  & > div {
    background-color: $joyMuted;
  }
}

.primaryColor {
  & > div {
    background-color: $joyPrimary;
  }
}

.secondaryColor {
  & > div {
    background-color: $joySecondary;
  }
}

.errorColor {
  & > div {
    background-color: $joyError;
  }
}

.whiteColor {
  & > div {
    background-color: $white;
  }
}

.blackColor {
  & > div {
    background-color: $black;
  }
}

@keyframes bounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
