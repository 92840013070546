@font-face {
  font-family: f37_ginger;
  src: url('../fonts/f37ginger-thin-webfont.eot');
  src: url('../fonts/f37ginger-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/f37ginger-thin-webfont.woff') format('woff'),
    url('../fonts/f37ginger-thin-webfont.ttf') format('truetype'),
    url('../fonts/f37ginger-thin-webfont.svg#f37_ginger') format('svg');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: f37_ginger;
  src: url('../fonts/f37ginger-light-webfont.eot');
  src: url('../fonts/f37ginger-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/f37ginger-light-webfont.woff') format('woff'),
    url('../fonts/f37ginger-light-webfont.ttf') format('truetype'),
    url('../fonts/f37ginger-light-webfont.svg#f37_ginger') format('svg');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: f37_ginger;
  src: url('../fonts/f37ginger-regular-webfont.eot');
  src: url('../fonts/f37ginger-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/f37ginger-regular-webfont.woff') format('woff'),
    url('../fonts/f37ginger-regular-webfont.ttf') format('truetype'),
    url('../fonts/f37ginger-regular-webfont.svg#f37_ginger') format('svg');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: f37_ginger;
  src: url('../fonts/f37ginger-bold-webfont.eot');
  src: url('../fonts/f37ginger-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/f37ginger-bold-webfont.woff') format('woff'),
    url('../fonts/f37ginger-bold-webfont.ttf') format('truetype'),
    url('../fonts/f37ginger-bold-webfont.svg#f37_ginger') format('svg');
  font-style: normal;
  font-weight: 700;
}
