@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  width: 100%;
  height: 100px;
  background-color: $white;
  box-shadow: $joyBoxShadowHeader;
  border: solid 1px $joyHeaderBorderColor;
  z-index: 1100;
}

.container {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  justify-content: space-between;
}

.containerMobile {
  padding-left: 20px;
  padding-right: 20px;
}

.content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.fixedHeader {
  position: fixed;
}
