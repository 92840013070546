@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  position: relative;

  &:hover > div.listContainer {
    opacity: 1;
    visibility: visible;
  }
}

.anchor {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}

.anchorIcon {
  margin-left: 10px;
}

.listContainer {
  position: absolute;
  top: 100%;
  right: -10px;
  padding-top: 15px;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 250ms linear;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &::before {
    top: 5px;
    right: 20px;
    z-index: 2;
    border-bottom: 10px solid $joyBorderColor;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  &::after {
    top: 7px;
    right: 21px;
    z-index: 3;
    border-bottom: 9px solid $white;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
  }
}

.itemList {
  background-color: $white;
  box-shadow: $joyBoxShadow;
  border: solid 1px $joyBorderColor;
  border-radius: 3px;
}

.itemLink,
.itemButton {
  white-space: nowrap;
  background-color: $white;
  color: $joyTextDefault;
  line-height: 40px;
  padding: 0 30px;
  cursor: pointer;
  transition: background-color 250ms ease;
}

.itemLink {
  &:hover {
    background-color: $joyTextSecondary;
    > a {
      color: $white;
    }
  }
}

.itemButton {
  &:hover {
    background-color: $joyTextSecondary;
    color: $white;
  }
}
