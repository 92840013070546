@import '@assets/css/variables.scss';

.wrapper {
  width: 1440px;
  margin: auto;
}

.wrapperMobile {
  margin: 0;
}

.heroImage {
  min-width: 1440px;
}

.heroImageMobile {
  width: 100%;
  height: auto;
}

.heroTitle {
  text-align: center;
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  position: relative;
  top: 40px;
  margin-left: 20px;
}

.widgetContainer {
  margin-top: 70px;
}

.sectionTestimonial {
  position: relative;
  margin-top: 100px;
  background-color: $white;
  text-align: center;
  padding-bottom: 50px;
}

.avatar {
  position: relative;
  top: -35px;
  width: 75px;
  height: 75px;
  border-radius: 40px;
  box-shadow: 0 2px 40px 0 $joyShadowColorMedia;
}

.separator {
  width: 75px;
  margin: 20px auto;
  border: 1px solid $joyPrimary;
}

.quote {
  width: 540px;
  margin: 0 auto;
}

.quoteMobile {
  padding: 0 20px;
  margin: 0 auto;
}

.sectionBrilliant {
  width: 100%;
  max-width: 1440px;
  margin-top: 80px;
  text-align: center;
}

.tabletBackground {
  background-image: url('/img/single-flat-home-transparent.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  padding-top: 30%;
  display: block;
}

.sectionUseSteps {
  margin: 120px auto;
}

.cardImage {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 45px;

  &::after {
    content: '';
    padding-top: 90%;
    display: block;
  }
}

.sectionWhyJoy {
  margin: 120px auto;
}

.sectionTitle {
  margin: 27px 1.5%;
}

.sectionContent {
  margin-top: 45px;
  display: block;
  position: relative;
  text-align: center;
}

.sectionCard {
  width: 30%;
  min-width: 350px;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin: 1.5%;
  line-height: 1.7;
  font-size: 1.2rem;
}

.sectionCardTitle {
  position: relative;
  margin-top: 23px;
  margin-bottom: 40px;

  &::after {
    width: 80px;
    height: 2px;
    border-radius: 2px;
    background: #ffbe00;
    content: '';
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
  }
}

.sectionJoyAlbum {
  width: 100%;
  margin: 80px auto;
  position: relative;
}

.albumBackground {
  background-image: url('/img/flat-lay-device.webp');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 10;

  &::after {
    content: '';
    display: block;
    max-height: 85vh;
    background: rgba(0, 0, 0, 0.4);
    z-index: 4;
    padding-top: 56.25%;
  }
}

.albumVideo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.albumContent {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  text-align: center;
}

.sectionCharging {
  margin: 120px auto;
  position: relative;
  line-height: 1.7;
}

.chargingContent {
  margin: 0 auto;
  position: relative;
  line-height: 1.7;
}

.chargingText {
  width: 90%;
  max-width: 450px;
  position: absolute;
  margin: 0 auto;
  right: 5%;
  bottom: 30%;
  z-index: 5;
}

.chargingBackground {
  background-image: url('/img/reverse-undocked_white_grande.png');
  background-size: 100% auto;
  overflow: hidden;
  max-height: 80vh;
  background-position: center left;
  background-repeat: no-repeat;
  background-color: #f2f1f2;

  &::after {
    content: '';
    padding-top: 50%;
    display: block;
  }
}

.sectionTechnical {
  margin: 120px auto;
}

.technicalImage {
  width: 100%;
}

.technicalSpecs {
  margin: 0 auto;
  padding: 45px 0;
  position: relative;

  &::before {
    width: auto;
    content: '';
    display: block;
    background: #bcbcbc;
    height: 1px;
    margin: 45px auto;
  }
}

.specsList {
  font-size: 1.2rem;
  padding-left: 0;
  list-style: none;
  counter-reset: specListCounter;

  & li {
    position: relative;
    list-style: none;
    padding-left: 40px;
    margin: 20px auto;

    &::before {
      position: absolute;
      left: 0;
      right: 0;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: #ffbe00;
      display: block;
      content: '';
    }

    &::after {
      content: counter(specListCounter);
      counter-increment: specListCounter;
      color: #fff;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 25px;
      text-align: center;
      font-size: 0.8em;
    }
  }
}

.specColumn {
  color: #3a3a3a;

  & h3 {
    margin: 25px auto 5px auto;
    font-size: 1.2em;
  }

  & p {
    max-width: 300px;
    margin: 5px 0;
    font-size: 1.2rem;
  }
}
