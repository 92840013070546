@import '@assets/css/variables.scss';

.noneFillColor {
  fill: none;
}

.defaultFillColor {
  fill: $joyIconDefault;
}

.disabledFillColor {
  fill: $joyIconDisabled;
}

.mutedFillColor {
  fill: $joyIconMuted;
}

.primaryFillColor {
  fill: $joyIconPrimary;
}

.secondaryFillColor {
  fill: $joyIconSecondary;
}

.errorFillColor {
  fill: $joyIconError;
}

.whiteFillColor {
  fill: $white;
}

.blackFillColor {
  fill: $black;
}

.noneStrokeColor {
  stroke: none;
}

.defaultStrokeColor {
  stroke: $joyIconDefault;
}

.disabledStrokeColor {
  stroke: $joyIconDisabled;
}

.mutedStrokeColor {
  stroke: $joyIconMuted;
}

.primaryStrokeColor {
  stroke: $joyIconPrimary;
}

.secondaryStrokeColor {
  stroke: $joyIconSecondary;
}

.errorStrokeColor {
  stroke: $joyIconError;
}

.whiteStrokeColor {
  stroke: $white;
}

.blackStrokeColor {
  stroke: $black;
}

.nonePadding {
  padding: $spaceNone;
}

.xsPadding {
  padding: $spaceXs;
}

.smPadding {
  padding: $spaceSm;
}

.mdPadding {
  padding: $spaceMd;
}

.lgPadding {
  padding: $spaceLg;
}

.xlPadding {
  padding: $spaceXl;
}
