@import '@assets/css/variables.scss';

.switch {
  display: block;
  flex: none;
  width: 60px;
  height: 26px;
  position: relative;
  border-radius: 26px;
  cursor: pointer;

  &::before {
    color: $white;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.55;
    user-select: none;
    position: absolute;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: $white;
    transform: translateY(-50%);
    transition: all 250ms ease-out;
  }
}

.switchInactive {
  @extend .switch;
  background: $joyMuted;

  &::before {
    content: 'OFF';
    top: 4px;
    right: 9px;
  }

  &::after {
    left: 2px;
  }
}

.switchActive {
  @extend .switch;
  background: $joySecondary;

  &::before {
    content: 'ON';
    top: 4px;
    left: 9px;
  }

  &::after {
    left: 36px;
  }
}
