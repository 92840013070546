@import '@assets/css/variables.scss';

.wrapper {
  width: 472px;
  max-height: 1150px;
  padding: 40px 50px;
  background-color: $white;
  margin-bottom: 50px;
}

.listActions {
  display: flex;
  flex-direction: row;
}

.addNewClient {
  margin-left: 10px;
}

.listTitle {
  margin-bottom: 17px;
  padding-top: 26px;
  padding-bottom: 11px;
  font-size: 12px;
  letter-spacing: 0.52px;
  line-height: normal;
  color: $joyMutedDark;
  border-bottom: 1px solid $joyBorderColor;
}

.clientList {
  max-height: 954px;
  min-height: 400px;
  overflow-y: auto;
}

.clientListLoop {
  margin-top: 100% !important;
}

.clientListMessage {
  text-align: center;
}

.clientItem {
  width: 100%;
  height: 74px;
  padding: 17px 15px;
  display: flex;
  flex-direction: row;
  border: 1px solid $white;
  border-bottom-color: $joyBorderColor;
  cursor: pointer;

  &:last-child {
    border-bottom-color: $white;
  }
}

.clientItemSelected {
  border-color: $joySecondary !important;
  border-radius: 3px;
}

.clientItemInfo {
  margin-left: 10px;
  flex: 1;
}

.loadMoreButton {
  margin: 30px;
  text-align: center;
}
