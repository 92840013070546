@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  border-radius: 3px;
  border: 1px solid $joyBorderColor;
  align-items: center;
  padding: 5px 10px;
}

.icon {
  margin-right: 5px;
}

.input {
  flex: 1 1;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  opacity: 0.4;
  color: $joyBlack;
}
