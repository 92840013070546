.wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  flex: none;

  &.hover {
    * {
      pointer-events: none;
    }
    &:after {
      opacity: 1;
    }
  }
}

.fileInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
