@import '@assets/css/variables.scss';

.row {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

.rowMobile {
  margin-bottom: 8px;
}

.itemSelect {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 50ms linear;
}

.showSelected {
  visibility: visible;
  opacity: 1;
}

.mediaGridMessage {
  text-align: center;
  margin-top: 30%;
}

.videoMark {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 1;
}

.mediaItem {
  position: relative;
  transition: all 100ms ease-in-out;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover {
    transform: scale(1.003);
    box-shadow: $joyBoxShadowMedia;

    button,
    svg {
      visibility: visible;
      opacity: 1;
    }
  }
}

.mediaItemMobile {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.mediaItemSelected {
  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $joyBlack;
    opacity: 0.7;
    top: 0;
    left: 0;
    display: block;
    content: '';
  }
}

.thumbnail {
  width: 100%;
  height: auto;
  float: left;
}
