@import '@assets/css/variables.scss';

.wrapper {
  flex: 1 1;
  padding: 15px 50px 0;
  margin-bottom: 50px;
}

.mediaActions {
  text-align: right;
  height: 100px;
  margin-bottom: 24px;
}

.selectionActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  background-color: $white;
  box-shadow: $joyShadowColor 0 5px 8px 0;
}

.selectionButtons {
  flex: 1;
  flex-direction: row-reverse;
}

.photosessionButtons {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.gridActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
}

.shareLink {
  margin-right: 20px;
}

.mediaGridMessage {
  text-align: center;
  margin-top: 30%;
}

.mediaLoadingLoop {
  margin-top: 40%;
}

.addMedia {
  margin-left: 30px;
}

.dialogTitle {
  text-align: center;
}

.dialogSubtitle {
  text-align: justify;
  margin-top: 30px;
}
