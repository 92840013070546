@import '@assets/css/variables.scss';

.wrapper {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
}

.wrapperMobile {
  padding-left: 20px;
  padding-right: 20px;
}

.container {
  width: 100%;
  margin-top: 30px;
}

.content {
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px $joyFooterBorderColor;
}

.contentMobile {
  padding-top: 25px;
  padding-bottom: 30px;
}

.left,
.right {
  display: flex;
  flex-direction: row;
}

.left > * {
  margin-right: 40px;
}

.right > * {
  margin-left: 40px;
}

.leftMobile,
.rightMobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 10px;
  }
}

.middleMobile {
  margin-top: 15px;
}
