@import '@assets/css/variables.scss';

* {
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  color: $joyBlack;
  font-size: 14px;
  line-height: 18px;
  background-color: $joyOffWhite;
  font-family: 'f37_ginger', sans-serif;
}

main[role='main'] {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

a,
a:visited {
  cursor: pointer;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'f37_ginger', sans-serif;
}

fieldset {
  border: none;
}

label {
  cursor: pointer;
}

img {
  image-rendering: -webkit-optimize-contrast;
  user-select: none;
}

.smoke-signal {
  display: flex;
  width: 100vw;
  color: $white;
  justify-content: center;
  padding: 0 30px;
  position: fixed;
  bottom: 28px;

  > *:first-child {
    display: flex;
    width: 100%;
    height: 85px;
    align-items: center;
    max-width: 1380px;
    padding: 0 30px;
    font-size: 16px;
    border-radius: 3px;
    background-color: #5dc9e2f2;
    box-shadow: 0 2px 75px 0 #0000004d;
  }

  &.dark > *:first-child {
    background-color: #333333f2;
  }
}
