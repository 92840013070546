@import '@assets/css/variables.scss';

.button {
  height: 46px;
  min-width: 110px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  user-select: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:disabled {
    cursor: wait;
  }
}

.smallSize {
  padding: 0 20px;
}

.normalSize {
  padding: 0 30px;
}

.largeSize {
  padding: 0 40px;
}

.buttonFilled {
  border: none;
}

.defaultColorFilled {
  background-color: $joyButtonBackgroundDefault;
  color: $joyButtonTextDefault;
}

.disabledColorFilled {
  background-color: $joyButtonBackgroundDisabled !important;
  color: $joyButtonTextDisabled !important;
}

.mutedColorFilled {
  background-color: $joyButtonBackgroundMuted;
  color: $joyButtonTextMuted;
}

.primaryColorFilled {
  background-color: $joyButtonBackgroundPrimary;
  color: $joyButtonTextPrimary;
}

.secondaryColorFilled {
  background-color: $joyButtonBackgroundSecondary;
  color: $joyButtonTextSecondary;
}

.errorColorFilled {
  background-color: $joyButtonBackgroundError;
  color: $joyButtonTextError;
}

.whiteColorFilled {
  background-color: $joyBlack;
  color: $joyDarkWhite;
}

.buttonOutlined {
  border-width: 1px;
  border-style: solid;
  background-color: $white;
}

.transparent {
  background-color: #00000000;
}

.defaultColorOutlined {
  border-color: $joyButtonTextDefault;
  color: $joyButtonTextDefault;
}

.disabledColorOutlined {
  border-color: $joyButtonBackgroundDisabled !important;
  color: $joyButtonBackgroundDisabled !important;
}

.mutedColorOutlined {
  border-color: $joyButtonTextMuted;
  color: $joyButtonTextMuted;
}

.primaryColorOutlined {
  border-color: $joyButtonBackgroundPrimary;
  color: $joyButtonBackgroundPrimary;
}

.secondaryColorOutlined {
  border-color: $joyButtonBackgroundSecondary;
  color: $joyButtonBackgroundSecondary;
}

.errorColorOutlined {
  border-color: $joyButtonBackgroundError;
  color: $joyButtonBackgroundError;
}

.whiteColorOutlined {
  border-color: $joyDarkWhite;
  color: $joyDarkWhite;
}
