.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  flex: 1 1;
  margin: auto;
}

.switchWatermarkLabel {
  margin-left: 10px;
  width: 125px;
}
