@import '@assets/css/variables.scss';

.wrapper {
  position: relative;
  margin-top: 32px;
  padding-top: 3px;
  padding-bottom: 6px;
  border-bottom: 1px solid $joyBorderColor;

  &:after {
    content: '';
    color: transparent;
    transition: color 0.2s ease-out;
  }

  &[data-error] {
    &:after {
      background-color: #ffffffe6;
      color: $joyTextError;
      position: absolute;
      right: 0;
      top: 13px;
      font-size: 14px;
      font-weight: 400;
      content: attr(data-error);
    }
  }
}

.wrapperError {
  border-bottom-color: $joyError;
  > label {
    color: $joyTextError;
  }
}

.input {
  width: 100%;
  border: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 41px;

  &[data-value='true'] {
    ~ label {
      opacity: 1;
      top: -12px;
    }
  }
}

.label {
  color: $joyMuted;
  position: absolute;
  font-size: 12px;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  text-transform: capitalize;
  transition: all 0.2s ease-out;
}
